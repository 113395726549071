<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        직원관리
        <v-tooltip
          right
          color="black"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              class="mt-1 ml-1"
              v-bind="attrs"
              v-on="on"
              @click="getGroupList()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        to="/account-form"
        v-if="checkFuncPermission('regist')"
      >
        직원등록
      </v-btn>
    </div>
    <div class="main-section">
      <search-filter
        :radioFilters="radioFilters"
        :selectFilters="selectFilters"
        :search="search"
        searchPlaceHolder="이름/아이디/직위"
        @updateRadio="updateRadio"
        @updateSelect="updateSelect"
        @updateSearch="updateSearch"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col class="d-flex align-center pl-0">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on: tooltipOn }">
              <v-chip
                class="mt-4 mr-2 pr-1 pl-1 flex-shrink-0"
                label
                outlined
                elevation
                link
                color="success"
                v-on="tooltipOn"
                @click="excelDownloadFunc()"
                :disabled="excelDownloadList === null"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-chip>
            </template>
            <span>직원목록 다운로드</span>
          </v-tooltip>
          <v-radio-group
            @change="itemsPerPage = $event"
            dense
            row
            :value="itemsPerPage"
            hide-details
          >
            <v-radio
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <div class="edit-btn-wrap d-flex">
          <v-chip
            label
            link
            class="pl-2 ml-2 d-flex align-center"
            depressed
            :disabled="selected.length === 0"
            color="secondary"
            @click="updateAuthModalOpen(selected)"
            v-if="checkFuncPermission('auth')"
          >
            <v-icon
              size="20"
              class="mr-2"
            >mdi-key-outline</v-icon>
            권한일괄수정({{ selected.length.toLocaleString('ko-KR') }})
          </v-chip>
          <v-chip
            label
            link
            class="pl-2 ml-2 d-flex align-center"
            depressed
            :disabled="selected.length === 0"
            @click="updateGroupModalOpen(selected)"
            v-if="checkFuncPermission('group')"
            color="accent"
          >
            <v-icon
              size="20"
              class="mr-2"
            >mdi-sitemap-outline</v-icon>
            그룹일괄수정({{ selected.length.toLocaleString('ko-KR') }})
          </v-chip>
        </div>
      </v-row>
      <v-data-table
        show-select
        :headers="headers"
        :items="members"
        item-key="id"
        :search="search"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        @page-count="pageCount = $event"
        hide-default-footer
        :loading="loading"
        v-model="selected"
        class="elevation-0"
        dense
        checkbox-color="primary"
        selectable-key="id"
      >
        <template #[`item.position`]="{ item }">
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-if="checkFuncPermission('defaultInfo')"
          >
            <template #activator="{ on, attrs }">
              {{ item.position }}
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="userPositionText = item.position"
              >
                <v-icon
                  small
                  color="primary"
                >mdi-pencil</v-icon>
              </v-btn>
            </template>
            <div class="text-input">
              <v-text-field
                :autofocus="true"
                solo
                :value="item.position"
                dense
                flat
                hide-details
                @input="userPositionText = $event"
                @keydown="if($event.key === 'Enter') {
                  updateDefaultInfo('position', item.id, userPositionText);
                }"
              ></v-text-field>
              <v-btn
                class="mt-1"
                color="primary"
                depressed
                dense
                small
                @click="updateDefaultInfo('position', item.id, userPositionText)"
              >수정</v-btn>
            </div>
          </v-menu>
          <div v-else>{{ item.position }}</div>
        </template>
        <template #[`item.name`]="{ item }">
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-if="checkFuncPermission('defaultInfo')"
          >
            <template #activator="{ on, attrs }">
              {{ item.name }}
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="userNameText = item.name"
              >
                <v-icon
                  small
                  color="primary"
                >mdi-pencil</v-icon>
              </v-btn>
            </template>
            <div class="text-input">
              <v-text-field
                :autofocus="true"
                solo
                :value="item.name"
                dense
                flat
                hide-details
                @input="userNameText = $event"
                @keydown="if($event.key === 'Enter') {
                  updateDefaultInfo('name', item.id, userNameText);
                }"
              ></v-text-field>
              <v-btn
                class="mt-1"
                color="primary"
                depressed
                dense
                small
                @click="updateDefaultInfo('name', item.id, userNameText)"
              >수정</v-btn>
            </div>
          </v-menu>
          <div v-else>{{ item.name }}</div>
        </template>
        <template #[`item.phone`]="{ item }">
          <div
            class="d-flex align-center justify-left"
            style="width: max-content"
          >
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <div
                  class="d-flex align-center cursor-pointer"
                  style="width: max-content"
                  v-on="on"
                  @click="getHistories(
                    'users.update',
                    'phone',
                    '전화번호 변경이력',
                    item,
                  )"
                >
                  <div v-if="item.phone != null">
                    {{ item.phone }}
                  </div>
                  <div
                    v-else
                    class="grey--text"
                  >
                    번호없음
                  </div>
                </div>
              </template>
              <span>전화번호 변경이력 열람</span>
            </v-tooltip>
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-if="checkFuncPermission('defaultInfo')"
            >
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  @click="userPhoneText = item.name"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-pencil</v-icon>
                </v-btn>
              </template>
              <div class="text-input">
                <v-text-field
                  :autofocus="true"
                  solo
                  :value="item.phone"
                  dense
                  flat
                  hide-details
                  @input="userPhoneText = $event"
                  @keydown="if($event.key === 'Enter') {
                    updateDefaultInfo('phone', item.id, userPhoneText);
                  }"
                ></v-text-field>
                <v-btn
                  class="mt-1"
                  color="primary"
                  depressed
                  dense
                  small
                  @click="updateDefaultInfo('phone', item.id, userPhoneText)"
                >수정</v-btn>
              </div>
            </v-menu>
          </div>
        </template>
         <template #[`item.groupNo`]="{ item }">
          <div
            class="d-flex align-center justify-left"
            style="width: max-content"
          >
            <v-tooltip
              top
              color="black"
            >
              <template #activator="{ on }">
                <div
                  class="d-flex align-center cursor-pointer"
                  style="width: max-content"
                  v-on="on"
                  @click="getHistories(
                    'users.update',
                    'groupNo',
                    '그룹 변경이력',
                    item,
                  )"
                >
                  {{ comm.getGroupFullPath(groups, item.groupNo, '그룹없음') }}
                </div>
              </template>
              <span>그룹 변경이력 열람</span>
            </v-tooltip>
            <v-menu
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                <v-btn
                  small
                  icon
                  v-on="on"
                  v-if="checkFuncPermission('group')"
                  @click="updateGroupModalOpen([
                  {
                    name: item.name,
                    id: item.id,
                    groupNo: item.groupNo,
                  }
                ])"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
        </template>

<!--  -->
        <template #[`item.dateWork`]="{ item }">
          {{ item.dateWork[item.dateWork.length - 1].start }}
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip
            class="pr-2"
            v-if="checkFuncPermission('status')"
            small
            link
            :color="radioFilters.status.color[item.status]"
            @click="updateDateModalOpen('status', item)"
          >
            {{ radioFilters.status.data[item.status + 1].name }}
            <v-icon
              small
              class="ml-1"
            >
              mdi-pencil
            </v-icon>
          </v-chip>
          <v-chip
            v-else
            small
            :color="radioFilters.status.color[item.status]"
          >
            {{ radioFilters.status.data[item.status + 1].name }}
          </v-chip>
        </template>
        <template #[`item.isSales`]="{ item }">
          <v-menu
            offset-y
            v-if="checkFuncPermission('status')"
          >
            <template #activator="{ on, attrs }">
              <v-chip
                class="pr-2"
                small
                outlined
                v-bind="attrs"
                v-on="on"
                :color="radioFilters.isSales.color[item.isSales === true?1:0]"
              >
                {{ radioFilters.isSales.data[item.isSales === true?2:1].name }}
                <v-icon
                  small
                  class="ml-1"
                >mdi-pencil</v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                class="options"
                @click="updateDefaultInfo('isSales', item.id, true)"
                v-if="item.isSales === false"
              >영업으로 변경</v-list-item>
              <v-list-item
                class="options"
                @click="updateDefaultInfo('isSales', item.id, false)"
                v-if="item.isSales === true"
              >일반으로 변경</v-list-item>
            </v-list>
          </v-menu>
          <v-chip
            v-else
            small
            outlined
            :color="radioFilters.isSales.color[item.isSales]"
          >
            {{ radioFilters.isSales.data[item.isSales + 1].name }}
          </v-chip>
        </template>
        <template #[`item.permission`]="{ item }">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="primary"
                v-on="on"
                @click="updateAuthModalOpen([{
                  id: item.id,
                  permission: item.permission,
                  name: item.name,
                }])"
              >
                <v-icon size="18px">mdi-key</v-icon>
              </v-btn>
            </template>
            <span>권한수정</span>
          </v-tooltip>
        </template>
        <template #[`item.rooms`]="{ item }">
          <v-tooltip
            top
            color="black"
          >
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="primary"
                v-on="on"
                :class="item"
                @click="checkStaffInfo(item)"
              >
                <v-icon size="18px">mdi-chat-question</v-icon>
              </v-btn>
            </template>
            <span>리딩방 설정</span>
          </v-tooltip>
        </template>
        <template #[`item.password`]="{ item }">
          <v-chip
            small
            label
            link
            outlined
            @click="resetPassword(item.id)"
          >초기화</v-chip>
        </template>
      </v-data-table>
      <div class="text-center pt-4">
        <v-pagination
          color="primary"
          v-model="page"
          :length="pageCount"
          totalVisible="15"
          depressed
        ></v-pagination>
      </div>
    </div>
    <dlg-user-group
      :show.sync="dialog.group.show"
      :groups="groups === undefined ? [] : groups"
      :type="2"
      :selectUsers="selectUsers"
      :active="dialog.group.active"
      :open="dialog.group.open"
      :func="updateStaffs"
      :thisGroup="dialog.group.thisGroup"
    />
    <dlg-user-auth
      :show.sync="dialog.permission.show"
      :type="dialog.permission.type"
      :permission.sync="dialog.permission.permission"
      :selectUsers="selectUsers"
      :func="updateStaffs"
      :presets="dialog.permission.presets"
    />
    <v-dialog
      @click:outside="dialog.datePicker.show = false;"
      v-model="dialog.datePicker.show"
      width="290px"
    >
      <div class="date-picker-title primary">
        <p class="tit">{{ dialog.datePicker.title }}</p>
        <v-chip
          small
          color="white"
        >{{ dialog.datePicker.name }}</v-chip>
      </div>
      <v-date-picker
        class="date-picker-content"
        v-model="dialog.datePicker.date"
        @input="dialog.datePicker.date = $event"
        no-title
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="dialog.datePicker.func"
        >
          {{ dialog.datePicker.btnText }}
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog
      v-model="roomDlg.show"
      scrollable
      max-width="500"
      @click="roomDlgInit"
    >
      <v-card>
         <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">리딩방수정</span>
          <v-chip small class="ml-2" v-if="roomDlg.staff !== null">
            {{ roomDlg.staff.name }}
          </v-chip>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box mt-0" style="border-top: 0px">
            <div class="section-wrap">
              <div class="section pl-0 pr-0 pt-0 pb-0" v-if="roomDlg.rooms.length > 0">
                <div class="d-flex flex-wrap room-wrap pl-2" style="max-width: 100%">
                  <v-checkbox
                    v-for="room in roomDlg.rooms"
                    :key="room.no"
                    v-model="room.checked"
                    :label="room.name"
                    :checked="true"
                    color="primary"
                    class="mt-2 pb-4 mr-4"
                    hide-details
                  />
                </div>
              </div>
              <div v-else class="pa-4 text-center pb-10">
                등록된 리딩방이 없습니다.
              </div>
            </div>
          </div>
        </v-card-text>
          <v-card-actions class="pb-6 pr-6 pt-0" v-if="roomDlg.rooms.length > 0">
            <v-spacer />
            <v-btn
              color="primary"
              depressed
              @click="updateRooms"
            >
              수정
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <dlg-history
      :show.sync="historyDlg.show"
      :data="historyDlg.data"
      :title="historyDlg.title"
      :sub="historyDlg.sub"
    />
    <dlg-customer-history
      :show.sync="customerHistoryDlg.show"
      :data="customerHistoryDlg.data"
      :title="customerHistoryDlg.title"
      :user="customerHistoryDlg.user"
      :command="customerHistoryDlg.command"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import DlgUserGroup from '@/components/dialog/DlgUserGroup.vue';
import DlgUserAuth from '@/components/dialog/DlgUserAuth.vue';
import DlgHistory from '@/components/dialog/DlgHistory.vue';
import DlgCustomerHistory from '@/components/dialog/DlgCustomerHistory.vue';
import time from '@/util/time';
import * as TreeModel from 'tree-model';
import comm from '@/util/comm';

export default {
  components: {
    SearchFilter,
    DlgUserGroup,
    DlgUserAuth,
    DlgHistory,
    DlgCustomerHistory,
  },
  name: 'Account',
  data: () => ({
    comm,
    historyDlg: {
      show: false,
      data: [],
      title: '',
      sub: null,
    },
    customerHistoryDlg: {
      show: false,
      data: [],
      user: null,
      command: null,
      title: null,
    },
    dialog: {
      datePicker: {
        title: null,
        btnText: null,
        name: null,
        id: null,
        date: null,
        show: false,
        func: null,
      },
      group: {
        show: false,
        thisGroup: null,
        active: [],
        open: [],
      },
      permission: {
        show: false,
        type: 5,
        uk: null,
        permission: {},
        presets: [],
      },
    },
    selected: [],
    selectUsers: [],
    tree: new TreeModel({
      childrenPropertyName: 'groups',
      modelComparatorFn: null,
    }),
    loading: true,
    radioFilters: {
      isSales: {
        key: 'isSales',
        name: '영업직여부',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '일반', value: false },
          { name: '영업', value: true },
        ],
        color: ['success', 'error', 'info'],
      },
      status: {
        key: 'status',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '재직중', value: 0 },
          { name: '퇴사', value: 1 },
          { name: '재입사', value: 2 },
        ],
        color: ['success', '', 'info '],
      },
    },
    selectFilters: {
      groupNo: {
        key: 'groupNo',
        name: '그룹',
        value: '전체',
        data: [],
      },
    },
    search: null,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    members: [],
    groups: [],
    excelDownloadList: [],
    roomDlg: {
      show: false,
      staff: null,
      rooms: [],
    },
    makeHistories: {
      phone: (data) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.phone !== undefined) {
            result.push({ ...r, after: r.after.phone });
          }
        });
        return result;
      },
      groupNo: (data) => {
        const result = [];
        data.forEach((r) => {
          if (r.after.groupNo !== undefined) {
            const typeObj = {
              insert: '배정',
              update: '변경',
            };
            result.push({ ...r, after: `[${typeObj[r.after.type]}] ${r.after.groupName} ${r.key}` });
          }
        });
        return result;
      },
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    headers() {
      const headArr = [
        {
          text: '직위',
          value: 'position',
          align: 'center',
        },
        {
          text: '이름',
          value: 'name',
        },
        {
          text: '아이디',
          value: 'id',
        },
        {
          text: '전화번호',
          value: 'phone',
        },
        {
          text: '그룹',
          value: 'groupNo',
          align: 'center',
          filter: (value) => {
            if (this.selectFilters.groupNo.value === '전체') return true;
            return value === this.selectFilters.groupNo.value;
          },
          sort: (a, b) => {
            const aPath = this.comm.getGroupFullPath(this.groups, a, '그룹없음');
            const bPath = this.comm.getGroupFullPath(this.groups, b, '그룹없음');
            if (aPath < bPath) return -1;
            if (aPath > bPath) return 1;
            return 0;
          },
        },
        {
          text: '최종입사일',
          value: 'dateWork',
          align: 'center',
          sort: (a, b) => {
            const aDate = time.moment(a[a.length - 1].start);
            const bDate = time.moment(b[b.length - 1].start);
            if (aDate.isAfter(bDate)) return -1;
            if (bDate.isAfter(aDate)) return 1;
            return 0;
          },
        },
        {
          text: '상태',
          value: 'status',
          align: 'center',
          filter: (value) => {
            if (this.radioFilters.status.value === '전체') return true;
            return value === this.radioFilters.status.value;
          },
        },
        {
          text: '영업직여부',
          value: 'isSales',
          align: 'center',
          filter: (value) => {
            if (this.radioFilters.isSales.value === '전체') return true;
            return value === this.radioFilters.isSales.value;
          },
        },
      ];
      if (this.checkFuncPermission('auth')) {
        headArr.push({
          text: '권한',
          value: 'permission',
          align: 'center',
          filterable: false,
          sortable: false,
        });
      }

      // headArr.push({
      //   text: '리딩방',
      //   value: 'rooms',
      //   align: 'center',
      //   filterble: false,
      //   sortable: false,
      // });
      if (this.checkFuncPermission('password')) {
        headArr.push({
          text: '비밀번호초기화',
          value: 'password',
          align: 'center',
          filterable: false,
          sortable: false,
        });
      }
      return headArr;
    },
  },
  methods: {
    roomDlgInit() {
      this.roomDlg.staff = null;
      this.roomDlg.rooms = [];
      this.roomDlg.show = false;
    },
    updateDateModalOpen(type, user) {
      const name = `${user.name}(${user.id})`;
      this.dialog.datePicker.name = name;
      this.dialog.datePicker.id = user.id;
      this.dialog.datePicker.date = time.moment().format('YYYY-MM-DD');
      if (type === 'status') {
        const status = user.status === 1 ? 2 : 1;
        const statusUpdate = () => {
          this.progress(true);
          this.$socket.emit('users.staffs.status.update', {
            items: [
              { id: user.id, status, date: this.dialog.datePicker.date },
            ],
          }, (resp) => {
            if (resp.success > 0) {
              this.alert(['success', '상태가 변경되었습니다.']);
              this.confirm({ show: false });
              this.dialog.datePicker.show = false;
              this.getGroupList();
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
              this.confirm({ show: false });
              this.progress(false);
            }
          });
        };
        const func = () => {
          this.confirm({
            show: true,
            func: statusUpdate,
            msg: `${name} 계정을 퇴사처리 할까요?`,
            btnText: '퇴사처리',
            color: 'error',
          });
        };
        this.dialog.datePicker.title = user.status === 1 ? '재입사일 선택' : '퇴사일 선택';
        this.dialog.datePicker.btnText = user.status === 1 ? '재입사로 변경' : '퇴사처리';
        this.dialog.datePicker.func = user.status === 1 ? statusUpdate : func;
        this.dialog.datePicker.show = true;
      }
    },
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    getUserList(userFilter = []) {
      this.members = [];
      this.$socket.emit('users.staffs.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: userFilter,
      }, (resp) => {
        if (resp.result === 'success') {
          this.members = resp.items;
          this.loading = false;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    getGroupList() {
      this.loading = true;
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          const userFilter = [{ condition: 'ne', column: 'id', value: 'admin' }];
          this.groups = resp.items.groups;
          this.getUserList(userFilter);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateAuthModalOpen(userArr) {
      this.progress(true);
      this.$socket.emit('permissions.preset.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          const presets = resp.items.filter((r) => r.no !== 1);
          const defaultPreset = resp.items.filter((r) => r.no === 1)[0].permission;
          this.selectUsers = userArr;
          this.dialog.permission.presets = presets;
          if (
            Object.keys(userArr[0].permission).length !== 0
            && userArr[0].permission != null
            && userArr.length === 1
          ) {
            this.dialog.permission.permission = comm.makeUserPermissionUpToDate(
              defaultPreset,
              userArr[0].permission,
            );
          } else {
            this.dialog.permission.permission = defaultPreset;
          }
          this.dialog.permission.show = true;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`], false);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateGroupModalOpen(userArr) {
      this.selectUsers = userArr;
      if (userArr[0].groupNo != null && userArr.length === 1) {
        const gNo = userArr[0].groupNo;
        const root = this.tree.parse({ groups: this.groups });
        const thisNode = root.first((node) => (node.model.no === gNo));
        const openArr = [];
        thisNode.getPath().forEach((r, i) => {
          if (i > 0) {
            openArr.push(r.model);
          }
        });
        this.dialog.group.open = openArr;
        this.dialog.group.active = [thisNode.model];
      } else {
        this.dialog.group.open = [];
        this.dialog.group.active = [];
      }
      this.dialog.group.show = true;
    },
    updateStaffs(usersArr, msg) {
      this.$socket.emit('users.staffs.update', {
        items: usersArr,
      }, (resp) => {
        if (resp.success > 0) {
          this.alert(['success', msg]);
          this.getGroupList();
        } else {
          this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
          console.error(resp);
        }
      });
    },
    updateUsers(usersArr, msg) {
      this.$socket.emit('users.update', {
        items: usersArr,
      }, (resp) => {
        if (resp.success > 0) {
          console.log(resp);
          this.alert(['success', msg]);
          this.getGroupList();
        } else if (resp.failsInfo[0].code === 'ER_DUP_ENTRY') {
          this.alert(['error', '중복된 전화번호입니다.']);
          console.error(resp);
        } else {
          this.alert(['error', '오류가 발생했습니다. 다시 시도해주세요.']);
          console.error(resp);
        }
      });
    },
    updateDefaultInfo(type, id, value) {
      const arr = [{ id }];
      arr[0][type] = value;
      let successMsg = '';
      let errorMsg = '';
      let dataCheck = false;
      if (type === 'position') {
        successMsg = '직위가 수정되었습니다.';
        errorMsg = '직위는 공백없이 1~15자 한글, 영문, 숫자로 입력해주세요.';
        dataCheck = /^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g.test(value);
      } else if (type === 'name') {
        successMsg = '이름이 수정되었습니다.';
        errorMsg = '이름은 공백없이 1~15자 한글, 영문, 숫자로 입력해주세요.';
        dataCheck = /^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g.test(value);
      } else if (type === 'isSales' || type === 'status') {
        successMsg = type === 'isSales' ? '영업직여부가 변경되었습니다.' : '상태가 변경되었습니다.';
        dataCheck = true;
      } else if (type === 'phone') {
        successMsg = '전화번호가 수정되었습니다.';
        errorMsg = '전화번호는 하이픈을 포함한 숫자로 입력해주세요.';
        dataCheck = /^\d{3}-\d{3,4}-\d{4}$/g.test(value);
      }
      if (dataCheck === true) {
        if (type === 'phone') {
          this.updateUsers(arr, successMsg);
        } else {
          this.updateStaffs(arr, successMsg);
        }
      } else {
        this.alert(['error', errorMsg]);
      }
    },
    // 리딩방 전화번호 기입 여부 체크
    checkStaffInfo(staff) {
      if (staff.status === 1) {
        this.alert(['error', '퇴사처리된 직원입니다.']);
      } else if (staff.phone !== null) {
        this.staffRoomsDlgOpen(staff);
      } else {
        this.alert(['error', '해당 계정에 연락처가 없습니다.']);
      }
    },
    staffRoomsDlgOpen(staff) {
      this.progress(true);
      this.roomDlg.staff = staff;
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.rooms = resp.items;
          this.$socket.emit('rooms.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['no'],
            sortDesc: [true],
            filters: [
              {
                condition: 'eq',
                column: 'disabled',
                value: 0,
              },
              {
                where: 'and',
                condition: 'eq',
                column: 'id',
                value: staff.id,
              },
            ],
          }, (response) => {
            if (response.result === 'success') {
              this.roomDlg.rooms = resp.items.map((room) => ({
                ...room,
                checked: response.items.filter((r) => r.no === room.no).length > 0,
              }));
              this.roomDlg.show = true;
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              console.error(response);
              this.progress(false);
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    updateRooms() {
      this.progress(true);
      this.$socket.emit('users.rooms.update', {
        id: this.roomDlg.staff.id,
        rooms: this.roomDlg.rooms.reduce((acc, room) => {
          if (room.checked === true) {
            acc.push({ no: room.no, name: room.name });
          }
          return acc;
        }, []),
      }, (resp) => {
        if (resp.result === 'success') {
          this.confirm({ show: false });
          this.roomDlgInit();
          this.alert(['success', '리딩방이 수정되었습니다.']);
          this.progress(false);
          this.roomDlg.show = false;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.roomDlg.show = false;
        }
      });
    },
    resetPassword(id) {
      const func = () => {
        this.progress(true);
        this.$socket.emit('users.reset', {
          id,
          newPassword: `${id}!@`,
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '비밀번호가 초기화되었습니다.']);
            this.confirm({ show: false });
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
            this.confirm({ show: false });
          }
          this.progress(false);
        });
      };
      this.confirm({
        show: true,
        func,
        msg: `${id} 계정의 비밀번호가<br/>${id}!@로 초기화됩니다.<br/><br/><span class="error--text text-body-2">※초기화 후 반드시 새로운 비밀번호를 설정하도록 안내바랍니다.</span>`,
        btnText: '초기화',
        color: 'error',
      });
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
      this.selected = [];
    },
    /* 셀렉트필터 업데이트 */
    updateSelect(value, key) {
      this.selectFilters[key].value = value;
      this.selected = [];
    },
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
      this.selected = [];
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      if (this.members.length <= 0) {
        this.alert(['error', '다운로드할 목록이 없습니다.']);
        this.progress(false);
        this.excelDownloadList = [];
      } else {
        const statusArr = ['재직중', '퇴사', '재입사'];
        const list = this.members.reduce((arr, r) => {
          let startDate = '-';
          if (r.dateWork.length > 1) {
            const dateWorkSort = [...r.dateWork];
            dateWorkSort.sort((a, b) => {
              const aDate = time.moment(a.start);
              const bDate = time.moment(b.start);
              if (aDate.isAfter(bDate)) return -1;
              if (bDate.isAfter(aDate)) return 1;
              return 0;
            });
            startDate = dateWorkSort[0].start;
          } else if (r.dateWork.length === 1) {
            startDate = r.dateWork[0].start;
          }
          const row = {};
          row['직위/직책'] = r.position;
          row['이름'] = r.name;
          row['아이디'] = r.id;
          row['그룹'] = comm.getGroupFullPath(this.groups, r.groupNo, '그룹없음 ');
          row['최종입사일'] = startDate;
          row['재직상태'] = statusArr[r.status];
          row['영업직여부'] = r.isSales === true ? '영업' : '일반';
          arr.push(row);
          return arr;
        }, []);
        this.excelDownloadList = list;
        comm.makeExcelFile(this.excelDownloadList, `직원목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
        this.progress(false);
      }
    },
    getHistories(action, columnName, title, item) {
      this.progress(true);
      this.$socket.emit('log.get', {
        action,
        key: item.id,
      }, (resp) => {
        if (resp.result === 'success') {
          const filterData = this.makeHistories[columnName](resp.items, this);
          this.historyDlg.data = filterData;
          this.historyDlg.show = true;
          this.historyDlg.title = title;
          this.historyDlg.sub = `${comm.getGroupFullPath(item.groupNo, '그룹없음 ')}${item.name}`;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    checkFuncPermission(funcName) {
      try {
        const userPermission = { ...this.userInfo.permission.admin.menu.account.func };
        let result = false;
        if (
          userPermission[funcName] !== undefined
          && userPermission[funcName].bool === true
        ) result = true;
        return result;
      } catch (error) {
        console.error('checkFuncPermission error');
        return false;
      }
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
        }
      },
    },
    members: {
      handler(val) {
        if (val.length > 0) {
          const groupFilterData = [
            { name: '전체', value: '전체' },
          ];
          val.forEach((row) => {
            groupFilterData.push({
              name: this.comm.getGroupFullPath(this.groups, row.groupNo, '그룹없음'),
              value: row.groupNo,
            });
          });
          this.selectFilters.groupNo.data = groupFilterData;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.date-picker-title {
  display: flex;
  align-items: center;
  padding: 10px;
  .tit {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 10px;
    color: #fff;
  }
}
.date-picker-content {
  border-radius: 0px;
}
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top: 2px;
  width: 210px;
  display: flex;
  align-items: center;
}
.edit-btn-wrap {
  margin-bottom: -20px;
}
.options {
  font-size: 0.9rem;
  padding: 0px 20px;
  min-height: 34px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.dlg-wrap {
  padding: 15px;
  .update-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .key {
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0;
      margin-right: 70px;
    }
    .value {
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
    }
  }
  .update-btn-wrap {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px dashed #ccc;
  }

  .tit {
    font-size: 1.1rem;
    font-weight: 500;
    padding-bottom: 10px;
    margin: 0;
  }
  .sub {
    font-size: 0.9rem;
    margin-top: -8px;
    margin-bottom: 6px;
  }
  .log {
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    padding: 10px;
    .log-row {
      margin: 0;
      display: flex;
      justify-content: space-between;
      & + .log-row {
        margin-top: 4px;
      }
      span {
        font-size: 0.8rem;
        color: #555;
        & + span {
          margin-left: 60px;
        }
      }
      span.log-span {
        color: #262626;
        font-weight: 500;
      }
    }
  }
}
</style>
